import React, { memo, useCallback, useState } from "react";

import {
  Paper,
  Grid,
  useMediaQuery,
  TextField,
  Modal,
  Checkbox,
  FormControlLabel,
  Typography,
  Alert,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import { LoanCardButton } from "../../../Components";
import { terms } from "../../../constants";
import { getStyles } from "./RequestTermsStyles";
import { toast } from "react-toastify";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(255,255,255,0.6)",
    borderRadius: "10px",
  },
});

export const LoanboardRequestModal = memo(
  ({
    open,
    closeModalHandler,
    submitCallback,
    PaymentComponent,
    publishData,
    revise,
    requestedAmount,
  }) => {
    const [checked, setCheck] = useState(false);
    const [signInputValue, setSignInputValue] = useState("");
    const [showPaymentComponent, setShowPaymentComponent] = useState(false);
    const theme = useTheme();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

    const styles = getStyles(PaymentComponent);

    const resetTermsForm = () => {
      setSignInputValue("");
      setCheck(false);
    };

    let requestLoanTerms = terms.requestLoan;

    const handleSubmitCallback = useCallback(
      (status) => {
        if (!checked || !signInputValue) {
          return toast.error(
            "Please check the checkbox and fill out the input"
          );
        }

        submitCallback(status);
      },
      [checked, signInputValue]
    );

    return (
      <Modal sx={{ zIndex: 1300 }} open={open}>
        <Paper
          className="request-terms-wrapper fade-in-slow"
          sx={styles.wrapper}
          elevation={isSmallMediaQuery ? 0 : 6}
        >
          {publishData?.isPublishAllowed === false && (
            <Alert severity="warning">
              Only one published request is allowed until your outstanding loan
              is repaid. You can save this request to the Dasboard.
            </Alert>
          )}
          {!PaymentComponent && (
            <Grid sx={{ overflowY: "auto" }}>
              <Typography
                fontWeight={700}
                sx={{ marginBottom: "1em", marginInline: "auto" }}
                fontSize={"20px"}
              >
                {requestLoanTerms.title}
              </Typography>
              {requestLoanTerms.text}
            </Grid>
          )}

          {!PaymentComponent && !revise && (
            <>
              <CssTextField
                sx={{ mt: 1 }}
                fullWidth
                placeholder={terms.requestLoan.signInputTitle}
                value={signInputValue}
                onChange={(e) => setSignInputValue(e.target.value)}
              />

              <FormControlLabel
                checked={checked}
                onChange={() => setCheck(!checked)}
                style={{ marginTop: "1em" }}
                label={
                  <Typography color="#727987" fontSize={"14px"}>
                    {terms.requestLoan.signText}
                  </Typography>
                }
                control={
                  <Checkbox sx={{ color: "#DDDDDD", borderRadius: "1px" }} />
                }
              />
            </>
          )}
          {PaymentComponent && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                overflowY: "auto",
                className: "teest",
              }}
            >
              {
                <PaymentComponent
                  payeerRole="borrower"
                  price={publishData?.price}
                  handleCancelBtnClick={() => {
                    resetTermsForm();
                    closeModalHandler();
                  }}
                  setShowPaymentComponent={setShowPaymentComponent}
                  submitCallback={() => submitCallback("published")}
                  requestedAmount={requestedAmount}
                  revise={revise}
                />
              }
            </Grid>
          )}
          <Grid
            item
            className="buttons-container"
            xs={12}
            spacing={1}
            sx={styles.buttonsContainer}
            container
          >
            <LoanCardButton
              title="Cancel"
              btnProps={{
                color: "secondary",
                variant: "contained",
              }}
              cb={() => {
                resetTermsForm();
                closeModalHandler();
              }}
              styles={styles.cancelButton}
            />
            {!showPaymentComponent && (
              <LoanCardButton
                title={"Confirm & Save"}
                styles={styles.saveButton}
                cb={() => handleSubmitCallback("saved")}
              />
            )}
            {!PaymentComponent && !revise && (
              <LoanCardButton
                title={terms.requestLoan.actionTitle}
                btnProps={{
                  variant: "contained",
                  disabled: publishData?.isPublishAllowed === false,
                }}
                cb={() => handleSubmitCallback("published")}
                styles={styles.publishButton}
              />
            )}
          </Grid>
        </Paper>
      </Modal>
    );
  }
);
