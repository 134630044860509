export const getStyles = (PaymentComponent) => ({
  wrapper: {
    position: "relative",
    height: "fit-content",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    padding: "1em 1.5em",
    borderRadius: "8px",
    maxHeight: "90%",
    maxWidth: {
      xs: "90%",
      sm: "75%",
      md: "450px",
    },
  },
  buttonsContainer: {
    width: "100%",
    marginTop: "0.5em",
    justifyContent: "center",
    ml: {},
  },
  buttonsWrapper: {
    height: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  cancelButton: {
    mr: 1,
    mt: 1,
    fontSize: "16px",
  },
  saveButton: {
    mr: 1,
    mt: 1,
    fontSize: "16px",
  },
  publishButton: {
    mr: 1,
    mt: 1,
    fontSize: "16px",
  },
});
