import React, { memo, useCallback, useState } from "react";

import {
  Paper,
  Grid,
  useMediaQuery,
  Modal,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";

import { LoanCardButton } from "../../../../Components";

export const GrantLoanTerms = memo(
  ({ open, closeModalHandler, submitCallback, isLoading }) => {
    const [checked, setCheck] = useState(false);
    const theme = useTheme();
    const smallMediaQuery = theme.breakpoints.between(0, 756);
    const isSmallMediaQuery = useMediaQuery(smallMediaQuery);

    const handleCancelBtnClick = () => {
      setCheck(false);
      closeModalHandler();
    };

    const actionBtnClick = useCallback(() => {
      if (!checked) {
        return toast("Please check the checkbox");
      } else {
        submitCallback();
      }
    }, [submitCallback, checked]);

    return (
      <Modal sx={{ zIndex: 1300 }} open={open}>
        <>
          <Paper
            className="fade-in-slow"
            sx={{
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              flexDirection: "column",
              padding: "1em 1.5em",
              borderRadius: "8px",
              maxHeight: "100%",
              width: "fit-content",
              maxWidth: {
                xs: "90%",
                sm: "75%",
                md: "450px",
              },
              overflowY: "auto",
            }}
            elevation={isSmallMediaQuery ? 0 : 6}
          >
            <Typography
              fontWeight={700}
              style={{ marginBottom: "1em" }}
              fontSize={"20px"}
            >
              Loan Grant Contract
            </Typography>
            <Typography fontSize={"16px"}>
              I have contacted the borrower, have done my due diligence and
              wishto proceed. I understand that getloanr.com is not a mediator
              and I waive any rights to persecute getloanr.com or it's
              subsidiaries.
            </Typography>

            <FormControlLabel
              checked={checked}
              onChange={() => setCheck(!checked)}
              style={{ marginTop: "1em" }}
              label={
                <Typography color="#727987" fontSize={"14px"}>
                  I understand that this contract is legally binding and a
                  breach of this contact will warrant neccesary repercussions.
                </Typography>
              }
              control={
                <Checkbox sx={{ color: "#DDDDDD", borderRadius: "1px" }} />
              }
            />
            <Grid
              item
              xs={12}
              spacing={1}
              sx={{
                width: "100%",
                marginTop: "0.5em",
                justifyContent: {
                  sm: "center",
                  md: "flex-end",
                },
                ml: {},
              }}
              container
            >
              <Grid
                item
                sx={{
                  height: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <LoanCardButton
                  title="Cancel"
                  btnProps={{
                    color: "secondary",
                    variant: "contained",
                    disabled: isLoading,
                  }}
                  cb={handleCancelBtnClick}
                  styles={{
                    mr: 1,
                    mt: { xs: 1, sm: 0 },
                    fontSize: "16px",
                  }}
                />

                <LoanCardButton
                  title="Confirm Request"
                  btnProps={{
                    variant: "contained",
                    disabled: isLoading,
                  }}
                  cb={actionBtnClick}
                  styles={{
                    mr: 1,
                    fontSize: "16px",
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </>
      </Modal>
    );
  }
);
