import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import { createLoans } from "../../../actions/loan";
import { LoanboardRequestModal } from "./LoanboardRequestModal";
import { LoanRequestForm } from "../components/LoanRequestForm";
import { StripePayments } from "../../../Components/Payments/Stripe/StripePyaments";

import { toast } from "react-toastify";

const Request = () => {
  const {
    auth: { user },
  } = useSelector((state) => state);
  let initialState = useMemo(
    () => ({
      requestedAmount: "",
      paybackAmount: "",
      date: "",
      borrowerLocation: "",
      paystubsFiles: [],
      isCollateralExist: false,
      isLoanEditing: false,
    }),
    []
  );
  const [requestState, setRequestState] = useState(initialState);

  const [showRequestTerms, setShowRequestTerms] = useState(false);

  const [isPaymentRequired, setIsPaymentRequired] = useState(false);
  const [revise, setRevise] = useState(false);
  const [publishData, setPublishData] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getRequestFormData = useCallback(
    (status) => {
      const formData = new FormData();
      formData.append("status", status);
      for (let key in requestState) {
        if (key === "collateral" && requestState[key]) {
          formData.append(key, JSON.stringify(requestState[key]));
        } else if (key === "paystubsFiles" && requestState[key]) {
          for (let image of requestState[key]) {
            formData.append(key, image.data);
          }
        } else {
          formData.append(key, requestState[key]);
        }
      }
      return formData;
    },
    [requestState]
  );

  const handleSubmitLoanResponse = useCallback(
    (toastMessage, response) => {
      if (!response.isPublishAllowed) {
        setPublishData(response);
      }
      if (response.status === "invalid") {
        setIsPaymentRequired(true);
        setPublishData(response);
        return;
      }
      /*If it's saving or publishing with valid subscription */
      if (response?.status === "published" || response?.status === "saved") {
        setShowRequestTerms(false);
        setRequestState(initialState);
        setIsPaymentRequired(false);
        navigate("/dashboard");
        toast(toastMessage);
      } else if (
        response?.status === "valid" &&
        response?.action === "surcharge"
      ) {
        setRevise(response);
        setIsPaymentRequired(true);
      }
      dispatch({ type: "UNLOAD" });
    },
    [requestState]
  );

  const submitLoanRequest = useCallback(
    async (loanStatus) => {
      const toastMessage =
        loanStatus === "saved"
          ? "Loan request has been saved to the dashboard"
          : "Loan request has been published";

      const formData = getRequestFormData(loanStatus);

      dispatch(
        createLoans(formData, (response) =>
          handleSubmitLoanResponse(toastMessage, response)
        )
      );
    },
    [requestState]
  );

  const handleCloseTermsModal = useCallback(() => {
    setIsPaymentRequired(false);
    setShowRequestTerms(false);
    setRevise(false);
  }, [setShowRequestTerms]);

  return (
    <Grid container justifyContent="center">
      {showRequestTerms && (
        <LoanboardRequestModal
          open={showRequestTerms}
          revise={revise}
          closeModalHandler={handleCloseTermsModal}
          submitCallback={submitLoanRequest}
          PaymentComponent={isPaymentRequired ? StripePayments : null}
          publishData={publishData}
          requestedAmount={requestState.requestedAmount}
        />
      )}

      <LoanRequestForm
        requestState={requestState}
        cancelClickHandler={() => navigate("/dashboard/loan-board")}
        submitButtonTitle="Next"
        setRequestState={setRequestState}
        submitCallback={() => setShowRequestTerms(true)}
      />
    </Grid>
  );
};

export default Request;
