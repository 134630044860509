import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router";

import { Grid, Typography, Button } from "@mui/material";

import { ReviewForm, PickDateInput } from "../../../Components";
import { updateLoan } from "../../../actions/loan";

import moment from "moment";
import { toast } from "react-toastify";
import { GrantLoanTerms } from "./components/GrantLoanTerms";
import { checkoutSubscriptionAPI } from "../../../api/payments";
import { SubscriptionModal } from "../components/SubscriptionModa";

const Single = () => {
  const [terms, setTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReviewFormOpen, setIsReviewFormOpen] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [newPaybackDate, setNewPaybackDate] = useState(null);

  const { type } = useParams();

  const {
    auth: { user },
  } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { loan } = location.state;

  let currentActionBtnTitle = "Confirm";
  let toastMessage = "Request has been sent successfully";
  switch (type) {
    case "grant":
      currentActionBtnTitle = "Grant Loan";
      toastMessage =
        "Loan has been granted and sent to the borrower for confirmation";
      break;
    case "confirm":
      currentActionBtnTitle = "Send request";
      toastMessage = "Loan grant has been confirmed";
      break;
    case "deny-grant":
      currentActionBtnTitle = "Send request";
      toastMessage =
        "Loan grant has been denied successfully. Other lenders are now welcome to contact you";
      break;
    case "repaid":
      currentActionBtnTitle = "Repaid";
      toastMessage = "Loan has been repaid. Please leave a review!";
      break;
    case "unpaid":
      currentActionBtnTitle = "Not Repaid";
      toastMessage =
        "Loan has been marked as not repaid. Please leave a review!";
      break;
    case "extend-date":
      toastMessage = "Request has been sent to the borrower for confirmation";
      break;
    case "confirm-extend-date":
      toastMessage = "Confirmation has been sent successfully";
      break;
    case "cancel-extend-date" || "confirm-extend-date":
      currentActionBtnTitle = "Send Request";
      break;

    default:
      break;
  }
  const currentCancelBtnPath =
    type === "grant" ? "/dashboard/loan-board" : "/dashboard/";

  const reviewReceiver =
    user?.id === loan.granted_by?.id ? loan.created_by : loan.granted_by;

  const getModalTitle = () => {
    if (type === "unpaid") return "a loan is not repaid?";
    if (type === "extend-date") return "extend a loan payback date?";
    if (type === "confirm-extend-date") return "confirm payback date extension";
    if (type === "cancel-extend-date") return "cancel payback date extension";
    if (type === "grant") return "Grant a loan?";
    if (type === "deny-grant") return "Deny a loan grant?";
    if (type === "confirm") return "Confirm a loan grant?";
    if (type === "repaid") return "a loan is repaid?";
  };

  const changeLoanStatus = () => {
    dispatch(
      updateLoan(
        loan.id,
        type,
        ({ data }) => {
          if (data.error) {
            setTerms(false);
            setIsLoading(false);
            toast.error(data.error);
          }

          if (data?.id) {
            setIsLoading(false);
            toast(toastMessage);
            if (["repaid", "unpaid"].includes(type)) {
              setIsReviewFormOpen(true);
            } else {
              navigate("/dashboard");
              return;
            }
          }
        },
        newPaybackDate && {
          paybackDateRequest: { date: newPaybackDate, status: "pending" },
        }
      )
    );
  };

  const submitGrantLoan = async () => {
    try {
      setTerms(null);
      dispatch({ type: "LOAD" });
      const { data } = await checkoutSubscriptionAPI("grant", loan.id);

      if (data.status === "invalid") {
        setShowSubscriptionModal(data);
        dispatch({ type: "UNLOAD" });
      } else {
        changeLoanStatus();
      }
    } catch (e) {
      toast.error("Something went wrong");
    }
  };

  const cancelTermsModal = () => {
    setTerms(false);
  };

  const currentActionBtnHandler =
    type === "grant" ? () => setTerms(true) : changeLoanStatus;

  const handleChangePaybackDate = (e) => {
    const { value } = e.target;

    setNewPaybackDate(value);
  };

  if (!loan) return null;
  return (
    <Grid
      container
      sx={{
        mt: "1rem",
        width: { xs: "100%", sm: "600px" },
        margin: {
          xs: 0,
          sm: "0 auto",
        },
      }}
    >
      <GrantLoanTerms
        open={!!terms}
        closeModalHandler={cancelTermsModal}
        submitCallback={submitGrantLoan}
        isLoading={isLoading}
      />
      <SubscriptionModal
        open={!!showSubscriptionModal}
        submitCallback={changeLoanStatus}
        closeModalHandler={() => setShowSubscriptionModal(null)}
        subscriptionData={showSubscriptionModal}
      />
      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          margin: "0 0 1em 0",
          backgroundColor: "white",
          border: "1px solid rgba(66, 103, 178,0.3)",
          boxShadow: "0px 1px 6px 0px #7C9AEA",
          borderRadius: "6px",
          padding: "1em",
        }}
      >
        <Grid item xs={12}>
          <Typography
            fontWeight={700}
            fontSize={"28px"}
            sx={{
              textTransform: "capitalize",
              textAlign: { xs: "center", sm: "start" },
            }}
          >
            {getModalTitle()}
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography style={{ fontSize: "20px" }}>Loan Amount</Typography>
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            <Typography style={{ fontSize: "20px" }}>
              ${loan.requestedAmount}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography style={{ fontSize: "20px" }}>
              {type.includes("extend") && "New "}Payback Date
            </Typography>
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            {type === "extend-date" ? (
              <PickDateInput
                extendDate
                dateState={newPaybackDate || ""}
                handleChangeState={handleChangePaybackDate}
              />
            ) : (
              <Typography style={{ fontSize: "20px" }}>
                {moment(loan.paybackDateRequest?.date || loan.date).format(
                  "MM/DD/YYYY"
                )}
              </Typography>
            )}
          </Grid>
        </Grid>

        {["confirm", "deny-grant"].includes(type) && (
          <Grid
            item
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography style={{ fontSize: "20px" }}>Lender</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent={"flex-end"}>
              <Typography style={{ fontSize: "20px" }}>
                @{loan.granted_by.username}
              </Typography>
            </Grid>
          </Grid>
        )}
        {["grant", "repaid", "unpaid"].includes(type) && (
          <Grid
            item
            container
            xs={12}
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography style={{ fontSize: "20px" }}>Borrower</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent={"flex-end"}>
              <Typography style={{ fontSize: "20px" }}>
                @{loan.created_by.username}
              </Typography>
            </Grid>
          </Grid>
        )}

        {!isReviewFormOpen ? (
          <Grid item spacing={1} container justifyContent={"flex-end"} xs={12}>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  fontSize: "1rem",
                  color: "black",
                }}
                onClick={() => navigate(currentCancelBtnPath)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                }}
                onClick={currentActionBtnHandler}
              >
                {currentActionBtnTitle}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <ReviewForm
            user={user}
            closeReviewForm={() => setIsReviewFormOpen(false)}
            reviewReceiver={reviewReceiver}
            loanID={loan.id}
            authorRole={loan.created_by.id === user.id ? "borrower" : "lender"}
            postReviewCallback={() => {
              setTerms(null);
              navigate("/dashboard");
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Single;
