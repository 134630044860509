import React, { useCallback, useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Avatar,
  Grid,
  List,
  Modal,
  Paper,
} from "@mui/material";

import moment from "moment";

import { getReviewAPI } from "../../api/review";
import { ReviewCard, PaypalPayments } from "../../Components";
import { ClipLoader } from "react-spinners";

import { getloans } from "../../api/loan";
import { LoansList } from "./LoansList";
import { toast } from "react-toastify";
import { handleOnMessageClick } from "../../utils";
import { SubscriptionModal } from "../../Dashboard/LoanBoard/components/SubscriptionModa";

const getClickableLoanStyle = (count) => {
  return {
    cursor: count ? "pointer" : "text",
    fontWeight: count ? "bold" : "normal",
    borderRadius: "8px",
  };
};

export const ProfilePage = () => {
  const [profileData, setProfileData] = useState(null);
  const { profileUser, lendered, borrowed, reviews } = profileData || {};
  const [loading, setLoading] = useState(false);
  const [selectedLoans, setSelectedLoans] = useState(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const {
    auth: { user },
    appSettings,
    chats: { chats },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const location = query.get("location");
  const target = query.get("target");

  let { id } = useParams();

  const getInitData = useCallback(
    async (page = 1) => {
      try {
        dispatch({ type: "LOAD" });
        const response = await getloans(
          `userID=${id}&type=profile&page=${page}`
        );
        if (response.status === 200) {
          dispatch({ type: "UNLOAD" });
          return response.data;
        }
      } catch (e) {
        dispatch({ type: "UNLOAD" });
        navigate("/dashboard/");
      }
    },
    [dispatch, id, navigate]
  );

  const getReviewSlice = useCallback(
    () => async () => {
      setLoading(true);
      const response = await getReviewAPI(
        `receiverID=${id}&page=${reviews.previousPage + 1}`
      );

      if (response.status === 200) {
        setProfileData((prevState) => {
          return {
            ...prevState,
            reviews: {
              isReviewsLeft: response.data.isReviewsLeft,
              results: [...prevState.reviews.results, ...response.data.results],
              previousPage: response.data.previousPage,
            },
          };
        });
        setLoading(false);
      }
    },
    [reviews?.previousPage, id]
  );

  const handleLoanCountClick = (loans, title) => {
    if (loans.length) {
      setSelectedLoans({ loans, title });
    } else {
      return;
    }
  };
  const onMessageClick = useCallback(() => {
    handleOnMessageClick(
      chats,
      user.id,
      setShowSubscriptionModal,
      dispatch,
      navigate,
      profileUser?.id
    );
  }, [handleOnMessageClick, profileUser?.id, chats, user.id]);

  useEffect(() => {
    if (id) {
      getInitData(1).then(({ data }) => {
        setProfileData(data);
      });
    }
  }, [id, dispatch, getInitData]);

  if (!profileData) return null;

  return (
    <Grid
      container
      sx={{
        width: { xs: "90vw", md: "70vw" },
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: "1rem",
      }}
    >
      <SubscriptionModal
        open={!!showSubscriptionModal}
        submitCallback={onMessageClick}
        closeModalHandler={() => {
          setShowSubscriptionModal(null);
        }}
        subscriptionData={showSubscriptionModal}
      />
      <LoansList
        isModalOpen={!!selectedLoans}
        closeModal={() => setSelectedLoans(null)}
        username={profileUser?.username}
        data={selectedLoans}
      />
      <Card
        sx={{
          width: "100%",
        }}
      >
        <Avatar
          alt="user"
          sx={{
            width: "calc(3em + 1vmin)",
            height: "calc(3em + 1vmin)",
            margin: "16px auto",
          }}
        >
          {profileUser?.username[0]}
        </Avatar>
        <Typography
          gutterBottom
          variant="body2"
          sx={{ fontSize: "1rem", textAlign: "center" }}
        >
          @{profileUser?.username}
        </Typography>
        <CardContent sx={{ pt: 0 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "center" }}
          >
            Member since {moment(profileUser?.createdAt).format("MM/YYYY")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {profileUser?.country}
          </Typography>
          <List
            sx={{
              "& li": {
                marginTop: "3px",
              },
            }}
          >
            {/* As Borrower */}
            <Typography>
              <span style={{ fontWeight: "bold" }}>(borrower)</span> @
              {profileUser.username} has repaid {borrowed.repaid.count} loan/s
              {borrowed.repaid.count > 0 &&
                ` for a total of ${borrowed.repaid.amount} dollar/s`}{" "}
              , has{" "}
              <span
                onClick={() =>
                  handleLoanCountClick(
                    borrowed.granted.loans,
                    "(borrower), loans in progress"
                  )
                }
                style={getClickableLoanStyle(borrowed.granted.loans.length)}
              >
                {borrowed.granted.loans.length}
              </span>{" "}
              loan/s in progress and{" "}
              <span
                onClick={() =>
                  handleLoanCountClick(
                    borrowed.unpaid.loans,
                    "(borrower), loans marked as not repaid"
                  )
                }
                style={getClickableLoanStyle(borrowed.unpaid.loans.length)}
              >
                {borrowed.unpaid.loans.length}
              </span>{" "}
              loan/s that was marked as not repaid
            </Typography>

            {/* As Lender */}
            <Typography sx={{ mt: "10px" }}>
              <span style={{ fontWeight: "bold" }}>(lender)</span> @
              {profileUser.username} got completely paid back{" "}
              {lendered.repaid.count} loan/s
              {lendered.repaid.count > 0 &&
                ` for a total of ${lendered.repaid.amount} dollar/s`}{" "}
              , has{" "}
              <span
                style={getClickableLoanStyle(lendered.granted.loans.length)}
                onClick={() =>
                  handleLoanCountClick(
                    lendered.granted.loans,
                    " (lender) loans in progress"
                  )
                }
              >
                {lendered.granted.loans.length}
              </span>{" "}
              loan/s in progress and{" "}
              <span
                onClick={() =>
                  handleLoanCountClick(
                    lendered.unpaid.loans,
                    '(lender) loans marked as not repaid")'
                  )
                }
                style={getClickableLoanStyle(lendered.unpaid.loans.length)}
              >
                {lendered.unpaid.loans.length}
              </span>{" "}
              loan/s that was marked as not repaid
            </Typography>
          </List>
        </CardContent>
        <CardActions sx={{ justifyContent: "center", margin: "1rem 0" }}>
          <Button
            color="primary"
            onClick={() =>
              navigate(`/${location}`, {
                state: {
                  receiver: id,
                  target,
                },
              })
            }
            sx={{
              textTransform: "none",
              textDecoration: "none",
              fontSize: "1rem",
              mr: "1rem",
            }}
            variant="contained"
          >
            Back
          </Button>
          <Button
            onClick={onMessageClick}
            sx={{
              textTransform: "none",
              fontSize: "1rem",
            }}
            variant="contained"
            disabled={user.id === id}
          >
            Message
          </Button>
        </CardActions>
        <Grid sx={{ padding: "0 16px 8px 16px" }}>
          <Typography variant="h5" sx={{ mb: "5px" }}>
            Reviews:
          </Typography>
          {!reviews?.results.length && <Typography>No reviews yet</Typography>}

          {!!reviews?.results.length && (
            <Grid container sx={{ flexDirection: "column" }}>
              {reviews?.results?.map((review) => {
                return <ReviewCard key={review._id} review={review} />;
              })}
              {reviews?.isReviewsLeft && (
                <Button
                  onClick={getReviewSlice(1)}
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    width: "fit-content",
                    margin: "0 auto",
                  }}
                  variant="contained"
                >
                  {loading ? (
                    <ClipLoader color="ffffff" loading={loading} />
                  ) : (
                    "Load More"
                  )}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};
