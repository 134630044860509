import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { AutorenewOutlined as AutorenewOutlinedIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { updateLoan } from "../../actions/loan";

import { LoanCard, Sort, LoanStatuses, PublishFee } from "../../Components";

import { ReactComponent as Warning } from "../../assets/warning.svg";
import { toast } from "react-toastify";
import moment from "moment";
import { DashboardRequestModal } from "./components/DashboardRequestModal";
import { StripePayments } from "../../Components/Payments/Stripe/StripePyaments";

const Home = ({ deleteLoanBtnClick, getDashboardData }) => {
  const {
    loans: { loans, granted },
    appSettings,
    auth: { user },
  } = useSelector((state) => state);

  const { saved = [], requested = [] } = loans;
  let { granted: grantedAsLender = [], paid = [] } = granted;

  const [openLoanStatuses, setOpenLoanStatuses] = useState(false);
  const [openPublishFee, setOpenPublishFee] = useState(false);

  const [openSort, setOpenSort] = useState(false);
  const [sortRule, setSortRule] = useState(null);
  const [hideCheckbox, setHideCheckbox] = useState([]);

  const [selectedGrantedLoan, setSelectedGrantedLoan] = useState(null);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [isPaymentRequired, setIsPaymentRequired] = useState(false);

  const [publishLoan, setPublishLoan] = useState(null);
  const [publishData, setPublishData] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const publishLoanRequest = useCallback(() => {
    dispatch(
      updateLoan(publishLoan.id, "publish", ({ data }) => {
        if (data?.requestedAmount) {
          navigate(`/dashboard/loan-board?id=${publishLoan}`);
          setPublishLoan(null);
          toast("Loan request has been published");
        } else if (!data.isPublishAllowed) {
          setPublishData(data);
        } else {
          setPublishData(data);
          setIsPaymentRequired(true);
        }
      })
    );
  }, [dispatch, navigate, publishLoan]);

  const handleApplySorting = () => {
    if (sortRule) {
      dispatch({ type: "SORT_LOANS", rule: sortRule });
      setOpenSort(false);
    }
  };

  const onGrantedLoanBtnClick = (loanData) => {
    setSelectedGrantedLoan(loanData);
  };

  const processGrantedLoan = useCallback(
    (type, loan) => {
      dispatch({ type: "LOAD" });
      navigate(`/dashboard/loan-board/${loan.id}/${type}`, {
        state: {
          loan,
        },
      });
      dispatch({ type: "UNLOAD" });
    },
    [navigate, dispatch]
  );

  const editLoanHandler = (loan) => {
    const {
      requestedAmount,
      paybackAmount,
      date,
      collateral,
      isCollateralExist,
      borrowerLocation,
      isLoanEditing,
      status,
    } = loan;
    dispatch(
      updateLoan(
        loan.id,
        "edit",
        (response) =>
          response.status === 200 &&
          navigate("loan-board/request/edit", {
            state: {
              loanId: loan.id,
              loan: {
                requestedAmount,
                paybackAmount,
                date,
                collateral,
                isCollateralExist,
                borrowerLocation,
                isLoanEditing,
                status,
              },
            },
          }),
        { isLoanEditing: true }
      )
    );
  };

  const handleLoanNotRepaid = useCallback(
    (loan) => {
      const isPaybackDatePassed = moment.utc().isAfter(moment(loan.date));
      if (!isPaybackDatePassed) {
        const formatedDate = moment(loan.date).format("MMM Do, YYYY");
        toast(`Loan could be marked as not repaid after - ${formatedDate}`);
      } else {
        navigate(`/dashboard/loan-board/${loan.id}/unpaid`, {
          state: { loan },
        });
      }
    },
    [navigate]
  );

  const handleHideCheckboxChange = ({ target }) => {
    const { id, checked } = target;
    let updatedState = new Set(hideCheckbox);
    if (checked) {
      updatedState.add(id);
    } else {
      updatedState.delete(id);
    }
    setHideCheckbox([...updatedState]);
  };

  const handleExtendPaybackDate = (type, loan, isBorrower) => {
    if (!isBorrower && loan.paybackDateRequest?.status === "pending") {
      toast.error("Previous request is on the Borrower confirmation");
      return;
    }
    navigate(`/dashboard/loan-board/${loan.id}/${type}`, {
      state: { loan },
    });
  };

  const Icon = selectedGrantedLoan ? (
    <Warning
      width="50px"
      height="50px"
      style={{ margin: "0 auto", marginBottom: "10px" }}
    />
  ) : (
    <></>
  );

  useEffect(() => {
    const filterRules = JSON.stringify({ hide: hideCheckbox });
    const filter = `f_granted=${filterRules}`;
    getDashboardData(filter);
  }, [hideCheckbox, getDashboardData]);

  return (
    <Grid>
      <Grid item container xs={12}>
        <DashboardRequestModal
          open={showRequestModal}
          PaymentComponent={isPaymentRequired ? StripePayments : null}
          publishData={publishData}
          submitCallback={publishLoanRequest}
          requestedAmount={publishLoan?.requestedAmount}
          closeModalHandler={() => {
            setPublishLoan(null);
            setPublishData(null);
            setShowRequestModal(false);
            setIsPaymentRequired(false);
          }}
        />
        <Grid
          container
          item
          xs={12}
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "1rem",
            alignItems: "center",
          }}
        >
          <Typography fontSize={"20px"} fontWeight={600}>
            Recent Activity
            <IconButton onClick={() => getDashboardData()}>
              <AutorenewOutlinedIcon sx={{ color: "rgba(25, 118, 210)" }} />
            </IconButton>
          </Typography>
          <Sort
            open={openSort}
            handleClose={() => setOpenSort(false)}
            setSortRule={setSortRule}
            dispatch={dispatch}
            onApplyClick={handleApplySorting}
          />
          <LoanStatuses
            open={openLoanStatuses}
            modalClose={() => setOpenLoanStatuses(false)}
          />
          <PublishFee
            open={openPublishFee}
            modalClose={() => setOpenPublishFee(false)}
            monthlySubscription={appSettings.monthlySubscription}
          />
          <Grid>
            <Button
              variant="text"
              sx={{
                width: "fit-content",
                textTransform: "none",
                paddingLeft: 0,
                mr: -1,
              }}
              onClick={() => setOpenSort(true)}
            >
              Sort by
            </Button>
            <Button
              variant="text"
              sx={{ textTransform: "capitalize", marginleft: "auto" }}
              onClick={() => setOpenLoanStatuses(true)}
            >
              Loan Statuses
            </Button>
            <Button
              variant="text"
              sx={{ textTransform: "capitalize", marginleft: "auto" }}
              onClick={() => setOpenPublishFee(true)}
            >
              Pricing
            </Button>
          </Grid>
        </Grid>
        {/*Saved Loan Requests */}
        <Accordion sx={{ backgroundColor: "#e7efff", width: "100%", mt: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={"18px"} fontWeight={400}>
              {`Saved Loans (${saved.length})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              item
              xs={12}
              sx={{ flexDirection: { sm: "column", md: "row" } }}
            >
              {saved?.length ? (
                saved.map((loan) => {
                  return (
                    <LoanCard
                      key={loan.id}
                      loan={loan}
                      deleteLoanBtnClick={deleteLoanBtnClick}
                      editLoanHandler={editLoanHandler}
                      backLocation="dashboard/"
                      handlePublishLoanRequest={() => {
                        setPublishLoan(loan);
                        setShowRequestModal(true);
                      }}
                    />
                  );
                })
              ) : (
                <Typography fontSize={"16px"} fontWeight={400}>
                  No Saved Loan Requests
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/*Loans Requested */}
        <Accordion sx={{ backgroundColor: "#e7efff", width: "100%", mt: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={"18px"} fontWeight={400}>
              {`Requested Loans (${requested.length})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              item
              xs={12}
              sx={{ flexDirection: { sm: "column", md: "row" } }}
            >
              {requested?.length ? (
                requested?.map((loan) => {
                  return (
                    <LoanCard
                      key={loan.id}
                      loan={loan}
                      deleteLoanBtnClick={deleteLoanBtnClick}
                      onGrantedLoanBtnClick={onGrantedLoanBtnClick}
                      processGrantedLoan={processGrantedLoan}
                      editLoanHandler={editLoanHandler}
                      backLocation="dashboard/"
                      postReviewCallback={getDashboardData}
                      handleExtendPaybackDate={handleExtendPaybackDate}
                    />
                  );
                })
              ) : (
                <Typography fontSize={"16px"} fontWeight={400}>
                  No Loans Requested
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/* Loans Granted */}
        <Accordion sx={{ backgroundColor: "#e7efff", width: "100%", mt: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={"18px"} fontWeight={400}>
              {`Granted Loans (${grantedAsLender.length})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Hide: </Typography>
            <FormGroup
              sx={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id="granted"
                    checked={hideCheckbox.includes("granted") || false}
                    onChange={handleHideCheckboxChange}
                  />
                }
                label="Granted"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="preconfirmed"
                    checked={hideCheckbox.includes("preconfirmed") || false}
                    onChange={handleHideCheckboxChange}
                  />
                }
                label="Preconfirmed"
              />
            </FormGroup>
            <Grid container item xs={12}>
              {grantedAsLender.length ? (
                grantedAsLender?.map((loan) => {
                  return (
                    !loan.isRepaid && (
                      <LoanCard
                        key={loan.id}
                        loan={loan}
                        backLocation="dashboard/"
                        handleLoanNotRepaid={() => handleLoanNotRepaid(loan)}
                        postReviewCallback={getDashboardData}
                        handleExtendPaybackDate={handleExtendPaybackDate}
                      />
                    )
                  );
                })
              ) : (
                <Typography fontSize={"16px"} fontWeight={400}>
                  No Loans Granted
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* Repaid Loans */}
        <Accordion sx={{ backgroundColor: "#e7efff", width: "100%", mt: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={"18px"} fontWeight={400}>
              {`Repaid Loans (${paid.length})`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item xs={12}>
              {paid?.length ? (
                paid?.map((loan) => {
                  return (
                    <LoanCard
                      key={loan.id}
                      loan={loan}
                      backLocation="dashboard/"
                      postReviewCallback={getDashboardData}
                    />
                  );
                })
              ) : (
                <Typography fontSize={"16px"} fontWeight={400}>
                  No Loans Repaid
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Home;
